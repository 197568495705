import React, { useEffect, useState } from "react";
import { postDataCustom } from "../../../../../../backend/api";
import AutoForm from "../../../../../core/autoform/AutoForm";
import styled from "styled-components";
import { noimage } from "../../../../../../images";
import { Button } from "../../../../../core/elements";
import { v4 as uuidv4 } from "uuid"; // Import the uuid function
import html2canvas from "html2canvas";

const CenteredDiv = styled.div`
  width: fit-content;
  max-width: 800px; /* Adjust as needed */
  padding: 0px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
  border-radius: 12px;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    border-radius: 0;
  }
`;
const PageWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  background: white;
  left: 0;
  bottom: 0;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 6px), repeating-linear-gradient(#b4b4b455, #b4b4b4);
  .close {
    display: none !important;
  }
  &.Horizontal {
    display: flex;
    @media (max-width: 768px) {
      height: auto;
      overflow: auto;
      .close {
        display: flex !important;
      }
    }
  }
  &.Vertical {
    background: #00000075;
  }
  ~ body {
    overflow: hidden;
  }
`;
const Content = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: auto;
  width: 40%;
  overflow: auto;
  padding: 30px;
  min-width: 450px;
  max-width: 60%;
  z-index: 1;
  &.Horizontal {
    width: 40%;
    position: initial;
    /* height: 100vh; */
    /* overflow: auto; */
    display: flex;
  }
  &.single {
    width: 500px;
    min-width: 500px;
  }
  &.Vertical {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    &.Horizontal {
      width: 100%;
      height: auto;
      overflow: hidden;
    }
  }
`;
const Poster = styled.div`
  width: ${({ width }) => width || 700}px;
  height: ${({ height }) => height || 400}px;
  background-color: #f5f5f5;
  /* background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl || "none"}); */
  background-size: cover;
  background-position: center;
  /* border: 1px solid #ccc; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  margin: 0 auto 40px;
  margin-top: 20px;
`;
const Banner = styled.div`
  position: fixed;
  left: auto;
  bottom: 0;
  top: 0;
  right: right;
  width: 60%;
  overflow: auto;
  align-items: center;
  display: flex;
  &.Horizontal {
    width: 60%;
    right: 0px;
    left: auto;
    height: 100vh;
    position: inherit;
  }
  &.single {
    width: calc(100% - 500px);
  }
  &.Vertical {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    &.Horizontal {
      width: 100%;
    }
    &.preview {
      position: fixed;
      inset: 0;
      z-index: 100;
      left: auto;
      right: auto;
      overflow: auto;
      backdrop-filter: blur(10px);
    }
  }
`;
const Header = styled.div`
  padding: 0px 20px 0px;
  background: ${(props) => props.event?.themeColor};
  color: ${(props) => props.event?.themeTextColor};
  align-items: center;
  display: flex;
  color: white;
  border-radius: 11px 11px 0px 0px;
  height: 50px;
  font-size: 16px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-size: 18px;
    color: white;
  }
  div {
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 5px;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
    border-radius: 0px;
    h2 {
      font-size: 15px;
    }
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 40%;
  margin-bottom: 60px;
  margin-top: 60px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  position: relative;
  padding-bottom: 10px;
  color: black;
  img {
    max-width: 100%;
  }
  &.embed {
    margin: 0px auto;
    padding: 10px 25px 25px;
    width: 400px;
    &.Horizontal {
      align-items: flex-start;
      justify-content: flex-start;
      /* overflow: auto; */
    }
    &.double {
      width: 650px;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 768px) {
    max-width: 768px;
  }
  @media screen and (max-width: 768px) {
    flex: 1 1 100%;
    width: auto;
    padding: 10px;
    margin: 0px auto;
    &.embed {
      margin: 0px auto;
      padding: 10px 25px 25px;
      width: 100%;
      &.Horizontal {
        /* max-height: inherit;
        min-height: auto !important;
        overflow: auto;
        display: flex;
        justify-content: flex-start; */
        /* max-height: 90vh; */
      }
      &.double {
        max-width: 100%;
      }
    }
  }
`;
const FormHeader = styled.div`
  text-align: left;
  padding: 10px 0 0 0;
  span > bold {
    font-weight: 700;
  }
  span > span {
    font-size: 17px;
  }
  > span {
    font-size: 1.5em;
    text-align: left;
    -webkit-box-align: baseline;
    align-items: baseline;
    font-weight: normal;
    flex-direction: column;
    color: black;
  }
  i {
    border: 1px dashed;
    padding: 0px 5px;
    margin-left: 5px;
    font-style: normal;
    cursor: pointer;
  }
  svg {
    margin: 0;
    font-size: 2px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
  }
`;
const TextElement = styled.div`
  color: ${({ color }) => color || "#000"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};
  font-style: ${({ fontStyle }) => fontStyle || "normal"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  align-content: ${({ alignContent }) => alignContent || "center"};
  line-height: ${({ lineHeight, fontSize }) => lineHeight || fontSize}px;
  position: absolute;
  top: ${({ element, scale }) => element.positionY * scale * 0.1 || 0}px;
  left: ${({ element, scale }) => element.positionX * scale * 0.1 || 0}px;
  width: ${({ element, scale }) => element.width * scale * 0.1 || 0}px;
  height: ${({ element, scale }) => element.height * scale * 0.1 || 0}px;
  word-wrap: break-word;
  outline: none;
`;

/* Image Element */
const ImageElement = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ borderRadius }) => borderRadius || 0}px;
  border: ${({ borderWidth, borderColor }) => (borderWidth ? `${borderWidth}px solid ${borderColor}` : `none`)};
  outline: none;
  position: absolute;
  top: ${({ element, scale }) => element.positionY * scale * 0.1 || 0}px;
  left: ${({ element, scale }) => element.positionX * scale * 0.1 || 0}px;
  width: ${({ element, scale }) => element.width * scale * 0.1 || 0}px;
  height: ${({ element, scale }) => element.height * scale * 0.1 || 0}px;
`;
const Posters = ({ data, config, setLoaderBox }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [userData, setUserData] = useState({});
  const [imageData, setImageData] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [scale, setScale] = useState(10);
  const [originalWidth, setOriginalWidth] = useState(500);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [uniqueId, setUniqueId] = useState(""); // State to hold the unique ID

  useEffect(() => {
    // Generate a unique ID on component mount
    const id = uuidv4();
    setUniqueId(id);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const calculateReduction = () => {
      if (originalWidth <= screenWidth) {
      } else {
        const reduction = ((originalWidth - screenWidth) / originalWidth) * 100;
        setScale(9.5 - Math.round(reduction * 100) / 100 / 10); // Round to 2 decimal places
      }
    };

    calculateReduction();
  }, [originalWidth, screenWidth]);

  // setLoaderBox(true, "Please wait while we are generating your poster!")
  useEffect(() => {
    if (config?.length > 0) {
      const ticket = config[0];
      const parsedData = JSON.parse(ticket.imageBulderData);
      setOriginalWidth(ticket.layoutWidth);
      const formAttributes = parsedData.map((attribute) => {
        if (attribute.type === "image") {
          return {
            type: "image",
            placeholder: attribute.label,
            name: `image-${attribute.id}`,
            validation: "",
            default: "false",
            tag: true,
            label: attribute.label,
            required: true,
            view: true,
            add: true,
            update: true,
            height: attribute.height,
            width: attribute.width,
          };
        } else if (attribute.type === "text") {
          return {
            type: "text",
            placeholder: attribute.label,
            name: `text-${attribute.id}`,
            validation: "",
            default: "",
            label: attribute.label,
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
          };
        } else {
          return null;
        }
      });

      setAttributes(formAttributes.filter((attr) => attr !== null));
      setSelectedTicket(ticket);
    }
  }, [config]);

  // const GeneratePoster = async () => {
  //   try {
  //     const post = userData;
  //     setLoaderBox(true, "Please wait while we are generating your poster!");
  //     const response = await postDataCustom({ ...post, uniqueId, posterId: selectedTicket._id }, "https://poster-builder-api.eventhex.ai/api/v1/user/get-my-poster");

  //     if (response.status === 200) {
  //       const base64 = response.data.response;
  //       const binaryData = atob(base64);
  //       const arrayBuffer = new ArrayBuffer(binaryData.length);
  //       const uint8Array = new Uint8Array(arrayBuffer);

  //       for (let i = 0; i < binaryData.length; i++) {
  //         uint8Array[i] = binaryData.charCodeAt(i);
  //       }

  //       const blob = new Blob([uint8Array], { type: "image/png" });

  //       // Use the navigator.msSaveBlob() method to trigger the download
  //       if (navigator.msSaveBlob) {
  //         navigator.msSaveBlob(blob, "event-poster.png");
  //       } else {
  //         // Fallback to the previous method for non-Apple devices
  //         const downloadUrl = URL.createObjectURL(blob);
  //         const downloadLink = document.createElement("a");
  //         downloadLink.href = downloadUrl;
  //         downloadLink.download = "event-poster.png";
  //         document.body.appendChild(downloadLink);
  //         downloadLink.click();
  //         document.body.removeChild(downloadLink);
  //         URL.revokeObjectURL(downloadUrl);
  //       }

  //       setLoaderBox(false);
  //     } else {
  //       console.error("Error generating poster:", response.error);
  //       setLoaderBox(false);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting poster data:", error);
  //   }
  // };
  const GeneratePoster = async () => {  
    try {  
      // Find the Poster element  
      const posterElement = document.querySelector('.Poster');  
      
      // Hide the buttons temporarily  
      const buttonContainer = posterElement.querySelector('div[style*="position: absolute"]');  
      const originalDisplay = buttonContainer.style.display;  
      buttonContainer.style.display = 'none';  
      
      // Generate canvas from the poster element  
      const canvas = await html2canvas(posterElement, {  
        scale: 2, // Higher quality  
        useCORS: true, // Handle cross-origin images  
        allowTaint: true,  
        backgroundColor: null,  
        logging: false,  
        onclone: (clonedDoc) => {  
          // Any modifications to cloned document before rendering  
          const clonedElement = clonedDoc.querySelector('.Poster');  
          if (clonedElement) {  
            clonedElement.style.transform = 'scale(1)';  
          }  
        }  
      });  
  
      // Restore the buttons  
      buttonContainer.style.display = originalDisplay;  
  
      // Convert canvas to blob  
      canvas.toBlob((blob) => {  
        if (!blob) {  
          console.error('Canvas to Blob conversion failed');  
          return;  
        }  
  
        // Create download link  
        const url = URL.createObjectURL(blob);  
        const link = document.createElement('a');  
        link.href = url;  
        link.download = 'poster.png';  
        
        // Trigger download  
        document.body.appendChild(link);  
        link.click();  
        
        // Cleanup  
        document.body.removeChild(link);  
        URL.revokeObjectURL(url);  
      }, 'image/png', 1.0);  
  
    } catch (error) {  
      console.error('Error generating poster:', error);  
      // Handle error appropriately (show user feedback)  
    }  
  };  
  return (
    <PageWrapper className={`${data.loginPage}`} event={data}>
      <Banner className={`${data.loginPage} ${showPreview ? "preview" : ""}`}>
        {selectedTicket && (
            <Poster className="Poster" width={selectedTicket.layoutWidth * scale * 0.1} height={selectedTicket.layoutHeight * scale * 0.1}>
              <img
                style={{ position: "absolute", width: selectedTicket.layoutWidth * scale * 0.1 + "px" }}
                alt="background"
                src={process.env.REACT_APP_CDN + selectedTicket.backgroundImage}
                onLoad={() => {
                  console.log("Background image loaded");
                  // setIsImageLoaded(true);
                }}
              />
              {JSON.parse(selectedTicket.imageBulderData).map((element) => (
                <React.Fragment key={element.id}>
                  {element.type === "text" && (
                    <TextElement scale={scale} element={element} color={element.color} fontSize={`${element.fontSize * scale * 0.1}px`} fontWeight={element.fontWeight} fontStyle={element.fontStyle} textAlign={element.textAlign} alignContent={element.alignContent} lineHeight={element.lineHeight * scale * 0.1}>
                      {userData?.["text-" + element.id] || element.label}
                    </TextElement>
                  )}
                  {element.type === "image" && (
                    <ImageElement
                      onError={(e) => {
                        e.target.src = noimage; // Hide the image on error
                      }}
                      scale={scale}
                      src={imageData?.["image-" + element.id + "preview"] ?? noimage}
                      alt="Element Image"
                      borderRadius={element.borderRadius * scale * 0.1}
                      borderWidth={element.borderWidth * scale * 0.1}
                      borderColor={element.borderColor}
                      element={element}
                    />
                  )}
                </React.Fragment>
              ))}

              <div style={{ display: "flex", gap: "10px", justifyContent: "center", position: "absolute", top: selectedTicket.layoutHeight * scale * 0.1 + 15, width: selectedTicket.layoutWidth * scale * 0.1 }}>
                <Button icon="edit" align="close" value={"Edit"} ClickEvent={() => setShowPreview(false)} />
                <Button icon="download" align="landing" isDisabled={!formValid} value={"Download Poster"} ClickEvent={GeneratePoster} />
              </div>
            </Poster>
        )}
      </Banner>
      <Content className={`${data.loginPage}`}>
        <CenteredDiv>
          <Header event={data}>
            <h2>{"Generate your poster!"}</h2>
          </Header>
          <FormContainer className={`${data.loginPage}  embed `}>
            {selectedTicket && (
              <React.Fragment>
                <FormHeader>
                  <span>
                    <strong>{selectedTicket.title}</strong>
                    , <br />
                    <p style={{ fontSize: "14px", fontStyle: "italic" }}>Please update necessary field and download your support poster</p>
                  </span>
                </FormHeader>
                <AutoForm
                  autoUpdate={true}
                  liveData={true}
                  useCaptcha={false}
                  key={`type-${selectedTicket.id}`}
                  useCheckbox={false}
                  customClass="embed"
                  description=""
                  formValues={{}}
                  formMode={"single"}
                  formType="post"
                  header=" "
                  formInput={attributes}
                  submitHandler={(data, status) => {
                    setFormValid(status);
                    setUserData((prev) => ({ ...prev, ...data }));

                    // Checking for images to be updated
                    Object.entries(data).forEach(([key, value]) => {
                      console.log(key);
                      if (key.startsWith("image-")) {
                        const file = data[key]?.[0];
                        if (file) {
                          // Create a FileReader to read the file as a Data URL
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            setImageData((prev) => ({ ...prev, [`${key}preview`]: e.target.result }));
                          };
                          reader.readAsDataURL(file); // Read the file as a Data URL
                        } else {
                          console.log("nofile", { data });
                        }
                      }
                    });
                  }}
                  button={"Download Poster"}
                  isOpenHandler={() => {}}
                  css="plain embed head-hide landing"
                  isOpen={true}
                  plainForm={true}
                />
                <div className="close" style={{ display: "flex", gap: "10px" }}>
                  <Button icon="download" type="close" align="close" value={"Preview"} ClickEvent={() => setShowPreview(true)} />
                  <Button icon="download" align="landing" isDisabled={!formValid} value={"Download Poster"} ClickEvent={GeneratePoster} />
                </div>
              </React.Fragment>
            )}
          </FormContainer>
        </CenteredDiv>
      </Content>
    </PageWrapper>
  );
};

export default Posters;
